#ContainerMapa {
  display: flex;
}
#Mapa {
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
#SeparadorPaises {
  height: 1px;
  border: 0;
  color: #666;
  background-color: black;
}
