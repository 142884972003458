#nombrePlaca {
  text-align: center;
}

#fotoPlaca {
  background-color: black;
}

#TarjetaModelos {
  border-radius: 2%;
  box-shadow: slategray;
}

#DivModelos {
  margin-right: 10px;
  margin-left: 10px;
}
