#Footer {
  background-color: #646464;
}

#Inti {
  display: flex;
  justify-content: center;
  align-items: center;
}
#FooterContacto {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 5px;
  margin-left: 5px;
  color: white;
}
#RedesSociales {
  display: flex;
  justify-content: center;
  align-items: center;
}
#DivContacto {
  display: flex;
  align-items: center;
}

#IconoContacto {
  color: #646464;
  background: #a9ba82;
  margin: 10px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  font-size: 20px;
}
/*
#IconoContacto {
  
  float: left;
  position: relative;
  transition: 0.5s ease-in-out;
  z-index: 1;
}
#IconoContacto:before {
  color: #646464;
  background: #a9ba82;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  transform: scale(1);
  transition: 0.5s ease-in-out;
  z-index: -1;
}

#IconoContacto:hover:before {
  color: black;
  background: #dee4a0;
  transform: scale(1);
}

#IconoContacto:hover {
  color: #646464;
  background: #a9ba82;
}
*/
#DatosContacto {
  display: flex;
  align-items: center;
}

/*Iconos redes sociales*/
#Redes {
  padding: 0;
  margin: 0;
  text-align: center;
}
.iconoFb {
  color: #3b5998;
}
.iconoIg {
  color: #d63876;
}
.iconoLd {
  color: #0e76a8;
}

.iconoFb,
.iconoIg,
.iconoLd {
  display: inline-block;
  width: 60px;
  height: 60px;
  background: #f1f1f1;
  margin: 10px;
  border-radius: 30%;
  box-shadow: 0 5px 15px -5px #00000070;
  overflow: hidden;
  position: relative;
}
.iconoFb i,
.iconoIg i,
.iconoLd i {
  line-height: 60px;
  font-size: 26px;
  transition: 0.2s linear;
}
.iconoFb:hover i,
.iconoIg:hover i,
.iconoLd:hover i {
  transform: scale(1.3);
  color: #f1f1f1;
}

.iconoFb::before {
  background: #3b5998;
}
.iconoIg::before {
  background: #d63876;
}
.iconoLd::before {
  background: #0e76a8;
}
.iconoFb::before,
.iconoIg::before,
.iconoLd::before {
  content: "";
  position: absolute;
  width: 120%;
  height: 120%;
  transform: rotate(45deg);
  left: -110%;
  top: 90%;
}
.iconoFb:hover::before,
.iconoIg:hover::before,
.iconoLd:hover::before {
  animation: aaa 0.7s 1;
  top: -10%;
  left: -10%;
}
@keyframes aaa {
  0% {
    top: -110%;
    left: 90%;
  }
  50% {
    top: 10%;
    left: -30%;
  }
  100% {
    top: -10%;
    left: -10%;
  }
}

#IconoContacto {
  -webkit-transition: background 0.2s, color 0.2s;
  -moz-transition: background 0.2s, color 0.2s;
  transition: background 0.2s, color 0.2s;
}

#IconoContacto:after {
  top: -7px;
  left: -7px;
  padding: 7px;
  box-shadow: 0 0 0 4px #fff;
  -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
  -webkit-transform: scale(0.8);
  -moz-transition: -moz-transform 0.2s, opacity 0.2s;
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transition: transform 0.2s, opacity 0.2s;
  transform: scale(0.8);
  opacity: 0;
}

/* Effect 1a */
#IconoContacto:hover {
  background: rgba(255, 255, 255, 1);
  color: #7da7bc;
}

#IconoContacto:hover:after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

#IconoContacto,
#IconoContacto:after,
#IconoContacto:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
