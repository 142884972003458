#BotonEnviarConsulta:hover {
  box-shadow: inset 0 100px 0 0 #7da7bc;
}

#BotonEnviarConsulta {
  background-color: #a9ba82;
  color: #646464;
  border: 2px solid;
  border-radius: 10px;
  padding: 18px 36px;
  display: inline-block;
  font-family: "Arial";
  letter-spacing: 1px;
  cursor: pointer;
  box-shadow: inset 0 0 0 0#dee4a0;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

#MensajeEnviado {
  transition-delay: 2s;
}
