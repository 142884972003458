#Body {
  overflow-x: hidden;
}

/* Separar secciones y darle color de fondo */
#Top,
.Colocacion,
.Resultados,
.Contacto {
  background-color: #dee4a0;
  padding-bottom: 20px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.Funcionamiento,
.Modelos,
.Distribuidores {
  background-color: #a9ba82;
  padding-bottom: 20px;
  padding-top: 10px;
}
#CarouselTop {
  background-color: #a9ba82;
}
/* Centrar titulos de secciones */
#TituloSeccion {
  text-align: center;
  padding-top: 50px;
  color: #646464;
  text-transform: uppercase;
}
