#Logo {
  margin-top: 80px;
  display: block;
  margin: auto;
}
#DescripcionPlaquia {
  margin-top: 30px;
  text-align: center;
}
#DivBotonCotizador {
  text-align: center;
}

#BotonCotizar {
  background-color: #dee4a0;
  color: #646464;
  border: 2px solid;
  border-radius: 10px;
  padding: 18px 36px;
  display: inline-block;
  letter-spacing: 1px;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #a9ba82;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}
#BotonCotizar:hover {
  cursor: pointer;
  box-shadow: inset 0 0 0 50px white;
  color: black;
}
