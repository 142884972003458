#linkInstructivo {
  text-decoration: none;
}
.BtnInstructivo {
  font-size: 30px;
  text-align: center;
  color: white;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  position: relative;
  background: #849275;
}
.BtnInstructivo a {
  color: rgba(51, 51, 51, 1);
  text-decoration: none;
  display: block;
}
.BtnInstructivo::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.BtnInstructivo:hover::before {
  opacity: 0;
  -webkit-transform: scale(1.2, 1.2);
  transform: scale(1.2, 1.2);
}
.BtnInstructivo::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.25);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5);
}
.BtnInstructivo:hover::after {
  cursor: pointer;
  opacity: 1;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}
